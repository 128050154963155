<template>
  <v-container class="pa-0">
    <v-card flat rounded color="#3e4c55" height="150px" width="100%">
      <v-btn small @click="goToVoucher" absolute right top>
        <v-icon small>mdi-backburger</v-icon>
        Back
      </v-btn>
      <div class="text-center pt-6 display-1 white--text">
        {{ voucherDetails.name }}
      </div>
      <div class="text-center grey--text text--lighten-5">
        {{ voucherDetails.description }}
      </div>
      <v-row>
        <v-spacer></v-spacer>
        <v-col md="3" class="text-center white--text">
          <v-btn class="mt-2" small block color="#008483" dark
            >Total Issues:<span style="text-transform: none">{{
              Number(voucherDetails.sales || 0) | numberFormatter
            }}</span></v-btn
          >
        </v-col>
        <v-col md="3" class="text-center white--text">
          <v-btn class="mt-2" small block color="#008483" dark
            >Pending Issues:
            {{ Number(voucherDetails.pending || 0) | numberFormatter }}</v-btn
          >
        </v-col>
        <v-col md="3" class="text-center white--text">
          <v-btn class="mt-2" small block color="#008483" dark
            >Sales:{{ Number(voucherDetails.revenue || 0) | toCurrency }}</v-btn
          >
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card>
    <div class="app">
      <div class="user-wrapper">
        <div>
          <div class="md-card md-theme-default">
            <div class="md-card-content">
              <v-row>
                <v-col md="3"
                  ><span class="header_title">Card Issues</span></v-col
                >
                <v-spacer></v-spacer>
                <v-col md="4">
                  <div style="float: right">
                    <v-btn
                      v-if="
                        checkExportPermission($modules.vouchers.issues.slug)
                      "
                      @click="exportData"
                      color="#00b0af"
                      dark
                      class="mr-2"
                    >
                      <v-icon>mdi-download</v-icon>&nbsp;&nbsp;Export
                    </v-btn>
                    <v-btn
                      v-if="
                        checkWritePermission($modules.vouchers.issues.slug) &&
                        voucherDetails.status_id == 1
                      "
                      @click="addCardCustomer"
                      color="#00b0af"
                      dark
                    >
                      Card
                      <v-icon class="pl-2" right dark
                        >mdi-credit-card-plus</v-icon
                      >
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="2">
                  <v-autocomplete
                    v-model="searchParams.time_intervel"
                    outlined
                    dense
                    label="Time Period"
                    @change="changeFxn"
                    item-value="name"
                    item-text="title"
                    :items="timeDuration"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="4" v-if="searchParams.time_intervel == 'custom'">
                  <date-period
                    style="margin-top: 1px"
                    @periodChange="dateChange"
                  ></date-period>
                </v-col>
                <v-spacer></v-spacer>
                <v-col md="2">
                  <div style="max-width: 110px; float: right">
                    <v-select
                      outlined
                      dense
                      v-model="perPage"
                      label="Per Page"
                      :items="[10, 15, 25, 50]"
                      @change="getVoucherSales()"
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
            </div>

            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <div
                    class="
                      md-content md-table-content md-scrollbar md-theme-default
                    "
                  >
                    <table>
                      <thead class="md-card-header">
                        <tr>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Timestamp
                              </div>
                              <div class="search_column">
                                <date-menu
                                  v-model="searchParams.timestamp"
                                  @change="getVoucherSales()"
                                >
                                </date-menu>
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Issue Date
                              </div>
                              <div class="search_column">
                                <date-menu
                                  v-model="searchParams.issue_date"
                                  @change="getVoucherSales()"
                                >
                                </date-menu>
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Expiry Date
                              </div>
                              <div class="search_column">
                                <date-menu
                                  v-model="searchParams.expiry_date"
                                  @change="getVoucherSales()"
                                >
                                </date-menu>
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Customer</div>
                              <div class="search_column">
                                <v-text-field
                                  label="Name"
                                  clearable
                                  solo
                                  v-model="searchParams.customer"
                                  append-icon="mdi-account-search"
                                  @click:append="getVoucherSales()"
                                  @click:clear="
                                    () => {
                                      searchParams.customer = null;
                                      getVoucherSales();
                                    }
                                  "
                                ></v-text-field>
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head" style="position: relative">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">
                                Voucher Code
                              </div>
                              <div class="search_column">
                                <v-text-field
                                  solo
                                  label="Voucher Code"
                                  clearable
                                  v-model="searchParams.voucher_code"
                                  append-icon="mdi-credit-card-search"
                                  @click:append="getVoucherSales()"
                                  @click:clear="
                                    () => {
                                      searchParams.voucher_code = null;
                                      getVoucherSales();
                                    }
                                  "
                                ></v-text-field>
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head" style="position: relative">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Status</div>
                              <div class="search_column">
                                <v-select
                                  solo
                                  :items="[
                                    { name: 'All', status: null },
                                    { name: 'Active', status: 1 },
                                    { name: 'Unpaid', status: 5 },
                                    { name: 'Expired', status: 10 },
                                  ]"
                                  item-text="name"
                                  label="Status"
                                  v-model="searchParams.status_id"
                                  item-value="status"
                                  @change="getVoucherSales()"
                                ></v-select>
                              </div>
                            </div>
                          </th>

                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label text-center">
                                Initial Balance
                              </div>
                              <div class="search_column">
                                <v-menu
                                  v-model="initialMenu"
                                  :close-on-content-click="false"
                                  max-width="180"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      label="Initial Balance"
                                      solo
                                      v-bind="attrs"
                                      v-on="on"
                                      max-width="180"
                                    >
                                    </v-text-field>
                                  </template>
                                  <v-card width="180">
                                    <v-card-text>
                                      <v-text-field
                                        dense
                                        v-model="searchParams.initial_from"
                                        label="Initial From"
                                      ></v-text-field>
                                      <v-text-field
                                        dense
                                        v-model="searchParams.initial_to"
                                        label="Initial To"
                                      ></v-text-field>
                                    </v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        @click="
                                          () => {
                                            searchParams.initial_to = null;
                                            searchParams.initial_from = null;
                                            initialMenu = false;
                                            getVoucherSales();
                                          }
                                        "
                                        >Clear</v-btn
                                      >
                                      <v-btn
                                        color="primary"
                                        text
                                        @click="
                                          () => {
                                            initialMenu = false;
                                            getVoucherSales();
                                          }
                                        "
                                        >Filter</v-btn
                                      >
                                    </v-card-actions>
                                  </v-card>
                                </v-menu>
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label text-center">
                                Current Balance
                              </div>
                              <div class="search_column">
                                <v-menu
                                  v-model="balanceMenu"
                                  :close-on-content-click="false"
                                  max-width="180"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                      label="Current Balance"
                                      solo
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                    </v-text-field>
                                  </template>
                                  <v-card width="180">
                                    <v-card-text>
                                      <v-text-field
                                        dense
                                        v-model="searchParams.balance_from"
                                        label="Current From"
                                      ></v-text-field>
                                      <v-text-field
                                        dense
                                        v-model="searchParams.balance_to"
                                        label="Current To"
                                      ></v-text-field>
                                    </v-card-text>
                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        @click="
                                          () => {
                                            searchParams.balance_from = null;
                                            searchParams.balance_to = null;
                                            balanceMenu = false;
                                            getVoucherSales();
                                          }
                                        "
                                        >Clear</v-btn
                                      >
                                      <v-btn
                                        color="primary"
                                        text
                                        @click="
                                          () => {
                                            balanceMenu = false;
                                            getVoucherSales();
                                          }
                                        "
                                        >Filter</v-btn
                                      >
                                    </v-card-actions>
                                  </v-card>
                                </v-menu>
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="
                                md-table-head-container md-ripple md-disabled
                              "
                            >
                              <div class="md-table-head-label">Receipt</div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr
                          class="md-table-row"
                          v-for="data in voucherIssues"
                          :key="data.id"
                        >
                          <td class="md-table-cell">
                            <div class="md-table-cell-container">
                              {{ data.created | timeStamp }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container">
                              <div class="user_date" v-if="data.issue_date">
                                {{ data.issue_date | dayFormat }}
                              </div>
                              <div class="user_date" v-else>NA</div>
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container">
                              <div class="user_date" v-if="data.expiry_date">
                                {{ data.expiry_date | dayFormat }}
                              </div>
                              <div
                                class="user_date"
                                v-else-if="data.issue_date"
                              >
                                Open
                              </div>
                              <div class="user_date" v-else>NA</div>
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container">
                              <router-link
                                v-if="
                                  checkReadPermission(
                                    $modules.clients.customers.id
                                  ) && data.customer_id
                                "
                                :to="`/clients/customers/` + data.customer_id"
                                >{{ data.first_name }}
                                {{ data.last_name }}</router-link
                              >
                              <span v-else-if="data.customer_id"
                                >{{ data.first_name }}
                                {{ data.last_name }}</span
                              >
                              <span v-else>Anonymous</span>
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container">
                              <v-btn
                                small
                                color="#00b0af"
                                dark
                                v-if="data.voucher_code == null"
                                @click="issueCard(data)"
                                >Issue</v-btn
                              >
                              <span v-else>{{ data.voucher_code }}</span>
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container">
                              {{ data.status }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container">
                              {{ Number(data.initial_balance) | toCurrency }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container">
                              {{ Number(data.current_balance) | toCurrency }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container">
                              <v-btn
                                normal
                                small
                                @click="getOrderDetails(data.order_id)"
                                >{{
                                  data.status == "Unpaid" ? "Pay" : "Receipt"
                                }}</v-btn
                              >
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <v-pagination
                  v-model="page"
                  :length="totalPages"
                ></v-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="purchaseModel" scrollable width="50%">
      <v-form ref="purchase_form" v-model="valid">
        <v-card>
          <v-card-title class="headline">
            <v-row no-gutters>
              <v-col md="6"> Card Purchase </v-col>
              <v-col
                md="6"
                class="text-right d-flex flex-row-reverse"
                v-if="!orderId"
              >
                <card-data-button
                  label="HID Omnikey"
                  @data="
                    (data) => {
                      setCardData(data);
                    }
                  "
                ></card-data-button>
                <card-reader-button
                  label="Samsotech Reader"
                  @data="
                    (data) => {
                      setCardData(data);
                    }
                  "
                ></card-reader-button>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="form_bg">
            <v-container>
              <v-row no-gutters justify="center">
                <v-col md="6">
                  <image-upload
                    @upload="
                      (data) => {
                        customer.profile_image = data;
                      }
                    "
                    @remove="
                      () => {
                        customer.profile_image = null;
                      }
                    "
                    ref="image_upload"
                    :height="150"
                    :image_path="customer.image_path"
                    defaultImage="user"
                  ></image-upload>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="6">
                  <v-mobile-search
                    :selected="customer.mobile"
                    v-model="customer.search"
                    @updateCustomer="setCustomerData"
                  ></v-mobile-search>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-name-search
                    label="Name*"
                    outlined
                    :selected="customer.name"
                    :mobile="customer.mobile"
                    :email="customer.email"
                    @updateCustomer="setCustomerData"
                    readonly
                    v-model="customer.nameSearch"
                  ></v-name-search>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    v-model="customer.email"
                    label="Email"
                    outlined
                    background-color="#fff"
                    :readonly="existingUser"
                    required
                    :rules="[
                      (v) => {
                        if (v) {
                          return /.+@.+\..+/.test(v);
                        }
                        return true;
                      },
                    ]"
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <date-of-birth v-model="customer.dob"></date-of-birth>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="customer.country_id"
                    :items="countries"
                    label="Nationality"
                    item-value="id"
                    item-text="name"
                    outlined
                    background-color="#fff"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <v-select
                    v-model="customer.gender"
                    :items="['Male', 'Female']"
                    label="Gender"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                  ></v-select>
                </v-col>
                <v-col md="6">
                  <date-field
                    v-model="customer.issue_date"
                    label="Issue Date"
                    :backFill="
                      checkBackfillPermission($modules.vouchers.issues.slug)
                    "
                  >
                  </date-field>
                </v-col>
                <v-col md="6">
                  <date-field
                    v-model="customer.expiry_date"
                    label="Expiry Date"
                    :backFill="
                      checkBackfillPermission($modules.vouchers.issues.slug)
                    "
                  >
                  </date-field>
                </v-col>

                <v-col md="6">
                  <v-text-field
                    v-model="customer.voucher_code"
                    label="Voucher Code"
                    outlined
                    background-color="#fff"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-select
                    label="Purchase From Service*"
                    :items="venueServices"
                    item-text="name"
                    item-value="venue_service_id"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    :rules="[(v) => !!v || 'Please select service']"
                    v-model="customer.venue_service_id"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-select
                    label="Card Type*"
                    :items="[voucherDetails]"
                    item-text="name"
                    item-value="id"
                    :rules="[(v) => !!v || 'Please select card type']"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    v-model="customer.voucher_id"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Price*"
                    :prefix="currencyCode"
                    :rules="[(v) => !!v || 'Price is required']"
                    :readonly="
                      voucherDetails.voucher_type == 'p' ||
                      customer.voucher_sales_id != null
                    "
                    v-model="customer.initial_balance"
                    outlined
                    background-color="#fff"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2 white--text blue-color"
              @click="cancelVoucherSale"
              >Close</v-btn
            >

            <v-btn class="ma-2 white--text teal-color" @click="sellVoucher">
              Save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <order-details
      :id="orderId"
      @close="orderId = null"
      @paymentDone="getVoucherSales"
    ></order-details>
  </v-container>
</template>

<script>
import moment from "moment";
import OrderDetails from "../../components/Order/OrderDetails.vue";
export default {
  components: {
    OrderDetails,
  },
  data() {
    return {
      voucherIssues: [],
      searchParams: {},
      timeDuration: [
        { name: "All", title: "All" },
        { name: "week", title: "This Week" },
        { name: "year", title: "This Year" },
        { name: "month", title: "This Month" },
        { name: "custom", title: "Custom Duration" },
      ],
      page: 1,
      totalPages: 1,
      perPage: null,
      receiptData: { id: null },
      customer: { dob: null, voucher_id: null, price: null },
      existingUser: false,
      purchaseModel: false,
      valid: false,
      voucherDetails: {},
      balanceMenu: false,
      initialMenu: false,
      pay_dialog: false,
      voucherId: null,
      orderId: null,
      isEmiratesIdCheck: false,
    };
  },
  mounted() {
    if (this.$store.getters.getCountries.status == false) {
      this.$store.dispatch("loadCountries");
    }
    if (this.$route.params.data != null) {
      this.voucherId = atob(this.$route.params.data);
      this.getVoucherDetails();
    }
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
  },
  watch: {
    page() {
      this.getVoucherSales();
    },
  },
  computed: {
    countries() {
      return this.$store.getters.getCountries.data;
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
  },
  methods: {
    setCardData(data) {
      this.setCustomerData(data);
      // if (!data.customer_id) {
      //   this.$set(this.customer, "customer_id", null);
      // }

      // if (!data.name && data.first_name) {
      //   this.$set(this.customer, "name", data.first_name);
      // }

      // if (!data.customer_id && this.customer.name != data.name) {
      //   this.$set(this.customer, "mobile", null);
      //   this.customer.search = null;
      //   this.customer.nameSearch = null;
      //   this.$set(this.customer, "email", null);
      //   this.$set(this.customer, "gender", null);
      //   this.$set(this.customer, "name", null);
      //   this.$set(this.customer, "customer_id", null);
      //   this.$set(this.customer, "first_name", null);
      //   this.$set(this.customer, "image_path", null);
      //   this.$set(this.customer, "dob", null);
      //   this.$set(this.customer, "country_id", null);
      //   this.$set(this.customer, "last_name", null);
      //   this.$set(this.customer, "opt_marketing", false);
      //   this.$forceUpdate();
      // }

      // if (data.mobile) {
      //   this.isEmiratesIdCheck = true;
      //   this.$set(this.customer, "mobile", data.mobile);
      // }
      // if (data.email) this.$set(this.customer, "email", data.email);
      // if (data.country_id) {
      //   this.$set(this.customer, "country_id", data.country_id);
      // } else {
      //   this.$set(this.customer, "country_id", null);
      // }
      // if (data.country_id) {
      //   this.$set(this.customer, "id_proof_type_id", data.id_proof_type_id);
      // }

      // if (data.id_proof_number) {
      //   this.$set(this.customer, "id_proof_number", data.id_proof_number);
      // }

      // if (data.gender) {
      //   this.$set(this.customer, "gender", data.gender);
      // } else {
      //   this.$set(this.customer, "gender", null);
      // }
      // if (data.dob) {
      //   this.$set(this.customer, "dob", data.dob);
      // } else {
      //   this.$set(this.customer, "dob", null);
      // }

      // if (data.image) {
      //   this.$set(this.customer, "image", data.image);
      // }

      // if (data.name) this.$set(this.customer, "name", data.name);
      // if (data.last_name) {
      //   this.$set(this.customer, "last_name", data.last_name);
      // } else {
      //   this.$set(this.customer, "last_name", null);
      // }
      // if (data.first_name)
      //   this.$set(this.customer, "first_name", data.first_name);
      // if (data.customer_id)
      //   this.$set(this.customer, "customer_id", data.customer_id);
      // if (data.image_path) {
      //   this.$set(this.customer, "image_path", data.image_path);
      // } else {
      //   this.$set(this.customer, "image_path", null);
      // }
      // if (data.opt_marketing) {
      //   if (data.opt_marketing == 1) {
      //     this.$set(this.customer, "opt_marketing", true);
      //   } else {
      //     this.$set(this.customer, "opt_marketing", false);
      //   }
      // }
      // this.$forceUpdate();
    },
    setCustomerData(data) {
      if (data.isEmiratesIdCheck) {
        this.isEmiratesIdCheck = true;
      }
      if (data.mobile && data.first_name && data.customer_id) {
        this.isEmiratesIdCheck = false;
      }
      if (!data.customer_id) {
        this.$set(this.customer, "customer_id", null);
      }

      if (!data.name && data.first_name) {
        this.$set(this.customer, "name", data.first_name);
      }

      if (
        this.customer.customer_id &&
        !data.customer_id &&
        this.customer.name != data.name &&
        this.customer.mobile != data.mobile
      ) {
        this.$set(this.customer, "mobile", null);
        this.customer.search = null;
        this.customer.nameSearch = null;
        this.$set(this.customer, "email", null);
        this.$set(this.customer, "gender", null);
        this.$set(this.customer, "name", null);
        this.$set(this.customer, "customer_id", null);
        this.$set(this.customer, "first_name", null);
        this.$set(this.customer, "image_path", null);
        this.$set(this.customer, "dob", null);
        this.$set(this.customer, "country_id", null);
        this.$set(this.customer, "last_name", null);
        this.$forceUpdate();
      }

      if (data.mobile) this.$set(this.customer, "mobile", data.mobile);
      if (data.email) this.$set(this.customer, "email", data.email);
      if (data.country_id) {
        this.$set(this.customer, "country_id", data.country_id);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.customer, "country_id", null);
        }
      }
      if (data.gender) {
        this.$set(this.customer, "gender", data.gender);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.customer, "gender", null);
        }
      }
      if (data.dob) {
        this.$set(this.customer, "dob", data.dob);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.customer, "dob", null);
        }
      }
      if (data.name) this.$set(this.customer, "name", data.name);
      if (data.last_name) {
        this.$set(this.customer, "last_name", data.last_name);
      } else {
        this.$set(this.customer, "last_name", null);
      }
      if (data.first_name)
        this.$set(this.customer, "first_name", data.first_name);

      if (data.customer_id)
        this.$set(this.customer, "customer_id", data.customer_id);
      if (data.image_path) {
        this.$set(this.customer, "image_path", data.image_path);
      } else {
        this.$set(this.customer, "image_path", null);
      }
      //this.$refs.form.resetValidation();
      this.$forceUpdate();
    },
    addCardCustomer() {
      this.customer = {};
      this.customer.voucher_id = this.voucherDetails.id;
      this.customer.initial_balance =
        this.voucherDetails.voucher_type == "c"
          ? null
          : parseFloat(this.voucherDetails.price);
      this.purchaseModel = true;
      setTimeout(() => {
        this.$refs["image_upload"].cancel();
        this.$refs.purchase_form.resetValidation();
      });
    },
    getVoucherDetails() {
      this.$http
        .get(`venues/vouchers/${this.voucherId}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.voucherDetails = response.data.data;
            this.getVoucherSales();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getVoucherSales() {
      let url = this.getFilterUrl();
      this.$http
        .get(
          `venues/vouchers/sales?voucher_id=${this.voucherId}&page=${
            this.page
          }&per_page=${this.perPage != null ? this.perPage : 10}${url}`
        )
        .then((response) => {
          if (response.status == 200) {
            this.voucherIssues = response.data.data;
            this.totalPages = response.data.total_pages;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    sellVoucher() {
      if (!this.$refs.purchase_form.validate()) {
        this.showError("Please enter all required fields");
        return;
      }
      if (this.customer.initial_balance <= 0) {
        this.showError("Initial balance should be greater than zero");
        return;
      }
      let formData = new FormData();
      Object.keys(this.customer).forEach((key) => {
        let value = this.customer[key];
        if (value != null) {
          formData.set(key, value);
        }
      });
      this.showLoader();
      this.$http
        .post(`venues/vouchers/sales`, formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=${form._boundary}",
          },
        })
        .then((response) => {
          if (response.status == 200) {
            this.purchaseModel = false;
            this.hideLoader();
            this.getVoucherSales();
            this.orderId = response.data.data.order_id;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFilterUrl() {
      let url = "";
      if (
        this.searchParams.time_intervel != "All" &&
        this.searchParams.time_intervel != null
      ) {
        if (this.searchParams.time_intervel == "week") {
          this.searchParams.start_date = moment()
            .startOf("week")
            .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "month") {
          this.searchParams.start_date = moment()
            .startOf("month")
            .format("YYYY-MM-DD");
        } else if (this.searchParams.time_intervel == "year") {
          this.searchParams.start_date = moment()
            .startOf("year")
            .format("YYYY-MM-DD");
        }
        url += "&end_date=" + this.searchParams.end_date;
        url += "&start_date=" + this.searchParams.start_date;
      }
      if (this.searchParams.timestamp != null) {
        url += "&timestamp=" + this.searchParams.timestamp;
      }
      if (this.searchParams.issue_date != null) {
        url += "&issue_date=" + this.searchParams.issue_date;
      }
      if (this.searchParams.expiry_date != null) {
        url += "&expiry_date=" + this.searchParams.expiry_date;
      }
      if (this.searchParams.initial_from != null) {
        url += "&initial_from=" + this.searchParams.initial_from;
      }
      if (this.searchParams.initial_to != null) {
        url += "&initial_to=" + this.searchParams.initial_to;
      }
      if (this.searchParams.balance_from != null) {
        url += "&balance_from=" + this.searchParams.balance_from;
      }
      if (this.searchParams.balance_to != null) {
        url += "&balance_to=" + this.searchParams.balance_to;
      }
      if (this.searchParams.status_id != null) {
        url += "&status_id=" + this.searchParams.status_id;
      }
      if (this.searchParams.voucher_code != null) {
        url += "&voucher_code=" + this.searchParams.voucher_code;
      }
      if (this.searchParams.customer != null) {
        url += "&customer=" + this.searchParams.customer;
      }
      return url;
    },
    getOrderDetails(id) {
      this.orderId = id;
    },
    issueCard(data) {
      this.customer.issue_date = moment().format("YYYY-MM-DD");
      this.customer.voucher_sales_id = data.voucher_sales_id;
      this.customer.venue_service_id = data.venue_service_id;
      this.customer.voucher_id = this.voucherDetails.id;
      this.customer.initial_balance = parseFloat(data.initial_balance);
      this.customer.image_path = data.profile_image;
      this.customer.customer_id = data.customer_id;
      this.$set(this.customer, "mobile", data.mobile);
      this.$set(this.customer, "email", data.email);
      this.$set(this.customer, "country_id", data.country_id);
      this.$set(this.customer, "gender", data.gender);
      this.$set(this.customer, "dob", data.dob);
      this.$set(
        this.customer,
        "name",
        `${data.first_name} ${data.last_name || ""}`
      );
      this.$set(this.customer, "last_name", data.last_name);
      this.$set(this.customer, "first_name", data.first_name);
      this.purchaseModel = true;
    },
    cancelVoucherSale() {
      this.purchaseModel = false;
    },
    exportData() {
      let url = this.getFilterUrl();
      this.$http
        .get(`venues/vouchers/export?voucher_id=${this.voucherId}${url}`, {
          responseType: "blob",
        })
        .then((response) => {
          if (response.status == 200) {
            this.downloadFile(response, "Voucher_Export_");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    goToVoucher() {
      this.$router.push({ name: "Vouchers" }, () => {});
    },
    dateChange(data) {
      this.searchParams.start_date = data.date1;
      this.searchParams.end_date = data.date2;
      this.getVoucherSales();
    },

    changeFxn() {
      this.searchParams.end_date = moment().format("YYYY-MM-DD");
      if (this.searchParams.time_intervel == "custom") {
        this.searchParams.start_date = moment()
          .subtract(30, "days")
          .format("YYYY-MM-DD");
      } else {
        this.getVoucherSales();
      }
    },
  },
};
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
tbody tr:hover {
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -moz-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  z-index: 1;
}
</style>
